<template>
  <div class="wrapper">
    <div class="total-box">
      <div class="item" @click="$router.push({ name: 'CustomerList' })">
        <div class="title">
          <span class="num"> {{ stat.userTotal || 0 }}</span>
          <span class="label">累计用户</span>
          <div class="sub-title">今日+{{ stat.userTodayTotal || 0 }}</div>
        </div>
      </div>
      <div class="item" @click="$router.push({ name: 'MerchantList' })">
        <div class="title">
          <span class="num"> {{ stat.merchantTotal || 0 }}</span>
          <span class="label">累计商户</span>
          <div class="sub-title">今日+{{ stat.merchantTodayTotal || 0 }}</div>
        </div>
      </div>
      <div class="item" @click="$router.push({ name: 'ActivityList' })">
        <div class="title">
          <span class="num"> {{ stat.activityTotal || 0 }}</span>
          <span class="label">累计活动</span>
          <div class="sub-title">今日+{{ stat.activityTodayTotal || 0 }}</div>
        </div>
      </div>
      <div class="item" @click="$router.push({ name: 'DynamicList' })">
        <div class="title">
          <span class="num"> {{ stat.userDynamicTotal || 0 }}</span>
          <span class="label">累计动态</span>
          <div class="sub-title">今日+{{ stat.userDynamicTodayTotal || 0 }}</div>
        </div>
      </div>
    </div>

    <div style="margin-top: 20px" class="chart-box">
      <LineChart v-if="show" title="近15天用户新增" :list_x="list_x" :list_y="list_y" />
    </div>
  </div>
</template>

<script>
import LineChart from './charts/line-chart.vue'
import { getIndexStatAPI } from './api'

export default {
  name: 'Home',
  components: { LineChart },
  data() {
    return {
      show: false,
      stat: {}
    }
  },
  computed: {
    list_x() {
      const { userStatList } = this.stat
      if (!userStatList) return []
      return userStatList.map((item) => item.day).reverse()
    },
    list_y() {
      const { userStatList } = this.stat
      if (!userStatList) return []
      return userStatList.map((item) => item.num).reverse()
    }
  },

  mounted() {
    this.getIndexStat()
  },

  methods: {
    async getIndexStat() {
      this.stat = await getIndexStatAPI()
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  // height: 120vh;
  // background-color: #f0f2f5;
  .total-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding-top: 10px;
    justify-content: center;
    .item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      padding: 50px 0;
      box-shadow: 0 0px 8px 0 rgba(114, 114, 114, 0.1);
      border-radius: 2px;
      transition: all 0.2s linear;
      &:hover {
        // transform: scale(1.01);
        box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
      }
      .title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 14px;
        }
        .num {
          font-weight: 700;
          font-size: 18px;
        }
        .sub-title {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(90%, -80%);
          white-space: nowrap;
          background-color: #0571f2;
          line-height: 20px;
          padding: 0 10px;
          border-radius: 20px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .chart-box {
    background-color: #fff;
  }
}
</style>
